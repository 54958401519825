import '../styles/globals.scss'
import { AppContext, AppInitialProps, AppLayoutProps } from 'next/app'
import { NextComponentType } from 'next'
import { getSession, SessionProvider } from 'next-auth/react'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useWindowFocus } from 'src/common/hooks/useWindowFocus/useWindowFocus'
import useGeoLocation from 'src/common/hooks/useGeoLocation/useGeoLocation'
import { hasCookie, setCookie } from 'cookies-next'
import { IdleTimeWrapper } from '@/modules/IdleTimeWrapper/IdleTimeWrapper'
import { setExpirationDays } from '@/utils/cookies/cookies'

const ACCEPT_TOU_ROUTE = '/accept-terms-of-use'
const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

const MyApp: NextComponentType<AppContext, AppInitialProps, AppLayoutProps> = ({
  Component,
  pageProps: { session, ...pageProps },
}: any) => {
  const [queryClient] = useState<QueryClient>(() => new QueryClient())
  const router = useRouter()
  const focused = useWindowFocus()
  const [locationAccess, setLocationAccess] = useState<boolean>(false)

  useEffect(() => {
    navigator.permissions
    .query({ name: 'geolocation' })
    .then((permissionStatus) => {
      if (permissionStatus.state === 'prompt') {
        permissionStatus.onchange = (ev) => {
          setLocationAccess(permissionStatus.state === 'granted')
        }
      } else {
        setLocationAccess(permissionStatus.state === 'granted')
      }
    })
  }, [locationAccess])

  const geoLocation = useGeoLocation(locationAccess)

  useEffect(() => {
    if (!process.env.FEATURE_GEOLOCATION) {
      setCookie('locale', 'en-us', {
        expires: setExpirationDays(90),
      })
      setCookie('countryCode', 'US', {
        expires: setExpirationDays(90),
      })
    } else {
      if (!hasCookie('locale')) {
        if (geoLocation.loaded) {
          setCookie('locale', geoLocation.locale, {
            expires: setExpirationDays(90),
          })
          setCookie('countryCode', geoLocation.countryCode, {
            expires: setExpirationDays(90),
          })

          router.push('/').then(() => {
            window.location.reload()
          })
        }
      }
    }
  })

  useEffect(() => {
    const sessionExpiryCheck = async () => {
      const sessionData = await getSession()

      // MULTI-TAB USER LOGOUT HANDLER
      if (
        focused &&
        !sessionData &&
        router.pathname.split('/')[1] === 'products' && router.pathname.split('/').length > 3 &&
        (pageProps.visibilityLevel === 'authenticated' ||
          pageProps.visibilityLevel === 'restricted' || 
          (pageProps.visibilityLevel === 'restricted-private'))
      ) {
        router.push('/')
      }
      else if (
        focused &&
        !sessionData &&
        router.pathname.split('/')[1] !== 'products' &&
        (pageProps?.pageData?.metaData?.protected === 'true' ||
          pageProps?.pageData?.metaData?.restricted === 'true')
      ) {
        router.push('/')
      }

      if (!sessionData && router.asPath === ACCEPT_TOU_ROUTE) {
        router.push('/')
      }

      // when user has not accepted tou and try going to any page but accept-tou we take user to accept tou page.
      if (
        router.pathname !== ACCEPT_TOU_ROUTE &&
        sessionData &&
        sessionData.decodedAccessToken.isTOUAccepted === false
      ) {
        router.push(ACCEPT_TOU_ROUTE)
      }

      // When user has already accepted, isTOUAccepted won't have any value, means it won't be false too.
      if (
        router.pathname === ACCEPT_TOU_ROUTE &&
        sessionData &&
        sessionData.decodedAccessToken.isTOUAccepted !== false
      ) {
        router.push('/')
      }
    }

    sessionExpiryCheck().then()
  })

  useEffect(() => {
    // NOTE: (only for elavon site), set "activeItem" in localStorage to 0, when user moves out of any product page
    if (
      process.env.SITE_BRAND === 'elavon' &&
      router.pathname.split('/')[1] !== 'products'
    ) {
      localStorage.removeItem('activeItem')
    }
  }, [router.pathname])

  // Devtools is hidden by default with prod build, but this will enable it in environment like DEV
  const showReactQueryDevToolWithBuild: boolean = ['DEV'].includes(
    String(process.env.ENV)
  )

  return (
    <>
      <SessionProvider
        // refetchInterval={5}
        refetchOnWindowFocus={false}
        session={session}
      >
        <QueryClientProvider client={queryClient}>
          <IdleTimeWrapper>
            <Component {...pageProps} />
          </IdleTimeWrapper>
          <ReactQueryDevtools />
          {showReactQueryDevToolWithBuild && (
            <React.Suspense fallback={null}>
              <ReactQueryDevtoolsProduction />
            </React.Suspense>
          )}
        </QueryClientProvider>
      </SessionProvider>
      {/* MFA Auth widget css issue fix  */}
      <style>{`
      .usb-dropdown .dropdown__list {
        margin-top: 0 !important;
      }
      .usb-dropdown .dropdown__list.expanded {
        width: auto !important;
      }
      .usb-dropdown.usb-dropdown__outlined .dropdown__btn {
        width: auto !important
      }
      @media (max-width: 1055px) {
        .usb-dropdown .dropdown__list.expanded {
          width: 100% !important;
        }
      }
      .aw-usb-authenticate span {
        font-size: 1rem !important;
      }
      .aw-usb-authenticate label span, .enrollmentLink {
        color: #555 !important;
      }
      `}</style>
      {/* MFA Auth widget css issue fix */}
    </>
  )
}

export default MyApp
